import {
  faChevronRight,
  faEdit,
  faVolumeMute,
  faTimes,
} from "@fortawesome/pro-light-svg-icons"
import { faEllipsisH, faEllipsisV } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fade } from "@material-ui/core"
import Popper from "@material-ui/core/Popper"
import {
  bindHover,
  bindPopper,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks"
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import ClickAwayListener from "react-click-away-listener"
import { useIndexedDB } from "../../hooks/use-indexeddb"
import { useModal } from "../../hooks/use-modal"
import { useStyle } from "../../hooks/use-style"
import { useUser } from "../../hooks/use-user"
import { darkTheme, lightTheme } from "../../services/theme"
import { LikeType, Comment } from "../../types"
import Moderation from "../moderation"

type Props = {
  comment: Comment
  onEdit: () => void
  onDelete: () => void
  hideComment: (commentId: string) => void
}

const CommentOptions: FunctionComponent<Props> = ({
  comment,
  onEdit,
  onDelete,
  hideComment,
}) => {
  const { user, accountState, setBalance, updateBalance } = useUser()
  const { setContent } = useModal()
  const { addKV } = useIndexedDB()

  const optionsPopupState = usePopupState({
    variant: "popper",
    popupId: "optionsPopper",
  })
  const markPopupState = usePopupState({
    variant: "popper",
    popupId: "markPopper",
  })
  const editPopupState = usePopupState({
    variant: "popper",
    popupId: "editPopper",
  })

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    infoColor:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    tagColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
  }))

  const deactivate = useCallback(() => {
    optionsPopupState.close()
  }, [optionsPopupState])

  /*
  const clickBoundary = useRef<HTMLDivElement>(null)
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        clickBoundary.current &&
        !clickBoundary.current.contains(event.target as Node)
      ) {
        window.requestAnimationFrame(() => deactivate())
      }
    },
    [deactivate]
  )

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [handleClickOutside])*/

  const editComment = useCallback(() => {
    onEdit()
    deactivate()
  }, [])

  const deleteComment = useCallback(() => {
    onDelete()
    deactivate()
  }, [])

  const muteCommentator = useCallback(
    (author: string, meta: any) => {
      let r = addKV("muted", comment.author.id, {
        meta: comment.author,
        ts: Math.floor(Date.now() / 1000),
      })
      if (r) {
        r.onsuccess = function () {
          console.log("Added")
        }
        r.onerror = function () {
          console.log("error")
        }
      }
      hideComment(comment.id)
      deactivate()
    },
    [comment]
  )

  const ref = useRef(null)

  const [] = useState(false)
  const [] = useState({ x: 0, y: 0 })

  return (
    <ClickAwayListener onClickAway={deactivate}>
      <div className="ellipsis">
        <button ref={ref} {...bindToggle(optionsPopupState)}>
          <FontAwesomeIcon icon={faEllipsisV} />
        </button>

        <Popper
          {...bindPopper(optionsPopupState)}
          transition
          placement="bottom"
          style={{ marginBottom: "0px" }}
        >
          {({}) => (
            <div className="panel" style={{ width: "200px" }}>
              <ul>
                {accountState >= 0 && (
                  <li>
                    <div className="submenuItem" {...bindHover(markPopupState)}>
                      <span className="menuLabel">Mark as</span>
                      <div className="">
                        <FontAwesomeIcon icon={faChevronRight} />
                      </div>
                    </div>

                    <Popper
                      {...bindPopper(markPopupState)}
                      transition
                      placement="right"
                      style={{ marginBottom: "0px", zIndex: "1000" }}
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={150}>
                          <Moderation
                            id={comment.id}
                            contentType={LikeType.Commentator}
                            recipient={comment.title}
                            borderColor={css.borderColor}
                          />
                        </Fade>
                      )}
                    </Popper>
                  </li>
                )}

                {user && user.id == comment.author.id ? (
                  <>
                    <li>
                      <div className="menuItem" onClick={editComment}>
                        <div className="badge">
                          <FontAwesomeIcon className="badge" icon={faEdit} />
                        </div>
                        <span className="menuLabel">Edit</span>
                      </div>
                    </li>
                    <li>
                      <div className="menuItem" onClick={deleteComment}>
                        <div className="badge">
                          <FontAwesomeIcon className="badge" icon={faTimes} />
                        </div>
                        <span className="menuLabel">Delete</span>
                      </div>
                    </li>
                  </>
                ) : (
                  <li>
                    <div className="submenuItem" onClick={muteCommentator}>
                      <div className="badge">
                        <FontAwesomeIcon
                          className="badge"
                          icon={faVolumeMute}
                        />
                      </div>
                      <span className="menuLabel">Mute Commentator</span>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          )}
        </Popper>
        <style jsx>{`
        .ellipsis {
          color: rgb(129, 129, 129);
          z-index: 2
        }
        .ellipsis:hover {
          color: blue;
        }
                  .panel {
                    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0, 0, 0, 0.19);
                    border-radius: 5px;
                    background-color: white;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    z-index: 1000;
      
                  }
                  .menuLabel {
                    display: table-cell;
                    vertical-align: middle;
                    white-space: nowrap;
                  }
                  .section {
                    margin-left: 10px;
                    margin-top: 10px;
                    font-weight: 500;
                  }
                  .badge {
                    margin-right: 10px;
                    font-size: 18px;
                    color: rgb(96,96,96);
                    width: 25px;
                  }
                  .menuItem {
                      padding: 3px 20px;
                      display: table;
                  }
                  .submenuItem {
                      padding: 10px 20px;
                      display: flex;
                      justify-content: space-between;
                      width: 100%;
                  }
      
                  ul {
                    margin: 0;
                    padding: 0;
                    font-size: 14px;
                    /*color: #747474;*/
                    font-family: "Roboto", sans-serif;
                  }
                  li {
                    display: flex;
                    /*align-items: center;*/
                  }
                  li:hover {
                    background-color: ${css.borderColor};
                    cursor: pointer;
                  }
        
                  .thumbsUpContainer {
                    /*transform: rotate(0.1deg);*/
                    color: rgb(129, 129, 129);
                  }
                  .thumbsUpContainer:hover {
                    cursor: pointer;
                    color: blue;
                  }
                  .thumbsUpContainerActive {
                    /*transform: rotate(1deg);*/
                    color: rgb(129, 129, 129);
                  }
                  .thumbsUpContainerActive:hover {
                    color: blue,
                  }
  
                  .thumbsUp {
                    z-index: 1;
                    margin-top: -3px;
                    /*color: rgb(96, 96, 96);*/
                    transform: rotate(1deg);
                    fill: rgb(129, 129, 129);
                    color: rgba(0, 0, 0, 0);
  
                    
                  }
                  .thumbsUp:hover {
                    color: blue;
                  }
                  .thumbsUpActive {
                    z-index: 1;
                    margin-top: "-3px";
                    color: blue,
                  }
                  .likeButton {
                  }
                  .likeButton:hover {
                    cursor: pointer;
                  }
                  article {
                      background-color: ${css.backgroundColor};
                      transition: 200ms background-color, 200ms border-color;
                      position: relative;
                  }
                  .interactable {
                      position: relative;
                      z-index: 1;
                  }
                  .label {
                      min-width: 0;
                  }
                  .image {
                      border-radius: 0;
                  }
                  header {
                      display: flex;
                      font-size: 0.8em;
                      overflow: hidden;
                  }
                  header > *:not(:last-child) {
                      margin-left: 1.6em;
                  }
                  h1,
                  .description {
                      margin: 0;
                  }
                  h1 {
                      font-size: 22px;
                      font-weight: 500;
                      margin-bottom: 0.5em;
                  }
                  p {
                      margin: 0;
                      font-size: 0.9em;
                      color: ${css.infoColor};
                      transition: 200ms color;
                  }
                  p:not(:last-child)::before {
                      content: "•";
                      margin-left: -0.5em;
                      padding-right: 0.5em;
                  }
                  .link {
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      font-size: 0;
                  }
                  .featured-comment {
                      transform: translateY(-2em);
                      margin-bottom: -2em;
                      margin-left: 10%;
                      margin-right: 20%;
                  }
                  button {
                      border-radius: 50%;
                      height: 2em;
                      width: 2em;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border: none;
                      background-color: transparent;
                      color: inherit;
                      transition: 200ms background-color;
                      z-index: 1;
                  }
                  button:disabled {
                      opacity: 0.5;
                  }
                  button:not(:disabled):hover {
                      background-color: ${css.backgroundColor};
                  }
                  .action-icon {
                      height: 100px;
                      width: 100px;
                      color: red;
                  }
                  .tag {
                    background-color: rgb(105, 105, 105, 0.1);
                    padding:5px;
                    margin-left:2px;
                    margin-right:2px;
                    border-radius: 5px;
                    font-size: 12px;
                    user-select: none;
                  }
                  .tag:hover {
                    background-color: rgb(105, 105, 105, 0.3);
                    cursor: pointer;
                  }
                  .actions {
                    display: table-row;
                    font-size: 14px;
                    color: #111111;
                    font-weight: 400;
                  }
                  .comments_count {
                    vertical-align: middle;
                    display: table-cell;
                    padding-right: 5px;
                  }
                  .comments {
                    display: table-cell;
                  }
                  .like {
                    display: table-cell;
                    vertical-align: middle;
                    margin-left: 15px;
                    font-size: 14px;
                  }
                  .table { 
                    display: table;
                 }
                 .table-row {display: table-row; }
                 .table-cell {display: table-cell;
                  vertical-align: middle; }
                  .comment-bubble {
                    font-size: 16px;
                    padding-left:5px;
                    padding-right:10px;
                    color: rgb(96, 96, 96);
                  }
                  .comment-bubble:hover {
                    color: blue;
                  }
                  .amount {
                    display: table-cell;
                    vertical-align: middle;
                    font-size: 14px;
                    font-weight: 500;
                    width: 50px;
                    text-align: right;
                  }
                 }
            `}</style>
      </div>
    </ClickAwayListener>
  )
}

export default CommentOptions
